import * as api from '../../utils/api';
const Cesium = window.Cesium;
export const defaultImageSize = 4000;

export function getTimeParameter() {
	return "?time=" + (new Date()).getTime();
}

export function extractContent(s, space) {
    var span= document.createElement('span');
    span.innerHTML= s;
    if(space) {
    var children= span.querySelectorAll('*');
        for(var i = 0 ; i < children.length ; i++) {
            if(children[i].textContent)
                children[i].textContent+= ' ';
            else
                children[i].innerText+= ' ';
        }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g,' ');
};

export function getDegree(input) {
    var parts = input.split(/[°'",]+/).join(' ').split(/[^\w\S]+/);

    if (parts.length === 3) {
        return Number(parts[0]) + Number(parts[1]) / 60 + Number(parts[2]) / 3600;
    }
    
    return 0;
}

export function getDateTime(input) {
    if (!input) return "";
    let imageLocationInfo = input.split("|");
    
    if (imageLocationInfo.length >=6 ){
        return imageLocationInfo[5];
    }

    return "";
}

export function getImageLocation(input) {
    let locationInfo = {
        longitude: null,
        latitude: null,
        altitude: null,
        date: "",
        make: "EXIF"
    }

    let imageLocationInfo = input.split("|");
    if (imageLocationInfo.length >= 5) {
        let longitude_direction = (imageLocationInfo[0] === "E")?1:-1;
        let latitude_direction = (imageLocationInfo[1] === "N")?1:-1;
        locationInfo.longitude = getDegree(imageLocationInfo[2]) * longitude_direction;
        locationInfo.latitude = getDegree(imageLocationInfo[3]) * latitude_direction;
        locationInfo.altitude = Number(imageLocationInfo[4]);
        
    }

    if (imageLocationInfo.length >= 6) {
        locationInfo.date = imageLocationInfo[5];
    }
    if (imageLocationInfo.length >= 7) {
        locationInfo.make = imageLocationInfo[6];
    }
    return locationInfo;
}

export function getLabelOfMeasure(distance, unit) {
    if (unit === "meter") {
        return distance.toFixed(2) + "m";
    }
    else {
        return (distance * 3.2808).toFixed(2) + "ft";
    }
}

export function getLabelOfMeasureFromOriginal(distance, original, unit) {
    if (unit === "meter" && original === "meter") {
        return distance.toFixed(2) + "m";
    }
    else if (unit === "imperial" && original === "imperial") {
        return distance.toFixed(2) + "ft";
    }
    else if (unit === "meter" && original === "imperial") {
        return (distance / 3.2808).toFixed(2) + "m";
    }
    else {
        return (distance * 3.2808).toFixed(2) + "ft";
    }
}

export function applyMeasureUnits(unit, labelMode) {
    if (!window.annotationList) return;
    window.annotationList.forEach(function(annotation, id) {
        for (let i = 0; i < annotation.length; i ++) {
            if (annotation[i].distancePositions !== undefined) {
                annotation[i]._label._text._value = getAnnotationMeasurementsLabel(annotation[i].distancePositions[0], annotation[i].distancePositions[1], labelMode, unit);
            }
        }
    });
}

export function getAnnotationMeasurementsLabel(position0, position1, labelMode, unit) {
    if (position0 && position1) {
        let cartographicPosition0 = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position0);
        let cartographicPosition1 = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position1);
        let xyzDistance = Cesium.Cartesian3.distance(position0, position1);
        let zDistance = Math.abs(cartographicPosition1.height - cartographicPosition0.height);
        cartographicPosition0.height = 0;
        cartographicPosition1.height = 0;
        let xyDistance = Cesium.Cartesian3.distance(window.MAIN_CESIUM_VIEWER.scene.globe.ellipsoid.cartographicToCartesian(cartographicPosition0), 
        window.MAIN_CESIUM_VIEWER.scene.globe.ellipsoid.cartographicToCartesian(cartographicPosition1));

        if (labelMode === "all") {
            return "XYZ: " + getLabelOfMeasure(xyzDistance, unit) +
                ", XY: " + getLabelOfMeasure(xyDistance, unit) +
                ", Z: " + getLabelOfMeasure(zDistance, unit);
        }
        else if (labelMode === "xyz") {
            return "XYZ: " + getLabelOfMeasure(xyzDistance, unit);
        }
        else if (labelMode === "xy") {
            return "XY: " + getLabelOfMeasure(xyDistance, unit);
        }
        else if (labelMode === "z") {
            return "Z: " + getLabelOfMeasure(zDistance, unit);
        }
    }

    return "";
}

export function drawAnnotations(annotationList, datasetList, unit) {
    if (!window.annotationList) window.annotationList = new Map();
    window.annotationList.forEach(function(annotation, id) {
        const found = annotationList.find(value => value.id === id);

        if (found) {
            for (let i = 0; i < annotation.length; i ++) {
                if (!datasetList.get(found.dataset_id)) {
                    window.MAIN_CESIUM_VIEWER.entities.remove(annotation[i]);
                    window.annotationList.delete(id);
                    continue;
                }
                annotation[i].show = found.visible && datasetList.get(-1).visible;
                if (annotation[i]._billboard) {
                    annotation[i]._billboard.color = Cesium.Color.fromCssColorString(getSeverityColor(found.severity_level)).withAlpha(1);
                    annotation[i]._billboard.image = '/images/' + found.severity_level + '_marker.png';
                }
                
                if (annotation[i]._point) {
                    annotation[i]._point.color = Cesium.Color.fromCssColorString(getSeverityColor(found.severity_level)).withAlpha(1);
                }

                if (annotation[i]._polyline) {
                    annotation[i]._polyline.material = new Cesium.PolylineGlowMaterialProperty({
                        color: Cesium.Color.fromCssColorString(getSeverityColor(found.severity_level)).withAlpha(1)
                    });
                    annotation[i]._polyline.depthFailMaterial = new Cesium.PolylineGlowMaterialProperty({
                        color: Cesium.Color.fromCssColorString(getSeverityColor(found.severity_level)).withAlpha(1)
                    });
                }
            }
        }
        else {
            for (let i = 0; i < annotation.length; i ++) {
                window.MAIN_CESIUM_VIEWER.entities.remove(annotation[i]);
            }
            window.annotationList.delete(id);
        }
    });

    for (let i = 0; i < annotationList.length; i ++) {
        let annotation = annotationList[i];
        if (window.annotationList.get(annotation.id)) continue;
        if (!datasetList.get(-1)) continue;
        let itemDataset = datasetList.get(-1);
        let entities = [];
        let positions = JSON.parse(annotationList[i].positions);
        if (annotation.type === "marker") {
            let entity = window.MAIN_CESIUM_VIEWER.entities.add({
                position: new Cesium.Cartesian3(positions[0], positions[1], positions[2]),
                billboard: {
                    image: '/images/' + annotation.severity_level + '_marker.png',
                    color: Cesium.Color.fromCssColorString(getSeverityColor(annotation.severity_level)).withAlpha(1),
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    verticalOrigin: Cesium.VerticalOrigin.CENTER,
                    width: 30,
                    height: 30,
                    scaleByDistance: new Cesium.NearFarScalar(2000000, 1, 16000000, 0.1),
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                },
                show: annotation.visible && itemDataset.visible
            });
            entity.dataset_id = annotation.dataset_id;
            entities.push(entity);
        }
        else if (annotation.type === "distance" || annotation.type === "polyline") {
            for (let i = 0; i < positions.length; i += 3) {
                let entity = window.MAIN_CESIUM_VIEWER.entities.add({
                    position: new Cesium.Cartesian3(positions[i], positions[i + 1], positions[i + 2]),
                    point: {
                        pixelSize: 10,
                        color: Cesium.Color.fromCssColorString(getSeverityColor(annotation.severity_level)).withAlpha(1),
                        disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    },
                    show: annotation.visible && itemDataset.visible
                });
                entity.dataset_id = annotation.dataset_id;
                entities.push(entity);

                if (i >= 3) {
                    let position0 = new Cesium.Cartesian3(positions[i - 3], positions[i - 2], positions[i - 1]);
					let position1 = new Cesium.Cartesian3(positions[i], positions[i + 1], positions[i + 2]);
                    let distance = Cesium.Cartesian3.distance(position0, position1);
                    entity = window.MAIN_CESIUM_VIEWER.entities.add({
                        polyline: {
                            positions: [position0, position1],
                            material: new Cesium.PolylineGlowMaterialProperty({
                                color: Cesium.Color.fromCssColorString(getSeverityColor(annotation.severity_level)).withAlpha(1)
                            }),
                            depthFailMaterial : new Cesium.PolylineGlowMaterialProperty({
                                color: Cesium.Color.fromCssColorString(getSeverityColor(annotation.severity_level)).withAlpha(1)
                            }),
                            width: 6,
                            disableDepthTestDistance: Number.POSITIVE_INFINITY,
                        },
                        show: annotation.visible && itemDataset.visible
                    });
                    entity.dataset_id = annotation.dataset_id;
                    entities.push(entity);

                    entity = window.MAIN_CESIUM_VIEWER.entities.add({
                        position: new Cesium.Cartesian3((position0.x + position1.x) / 2, (position0.y + position1.y) / 2, (position0.z + position1.z) / 2),
                        label: {
                            text: getAnnotationMeasurementsLabel(position0, position1, itemDataset.labelMode, unit),
                            font: "20px Helvetica",
                            showBackground: true,
                            fillColor: Cesium.Color.BLACK,
                            outlineColor: Cesium.Color.WHITE,
                            outlineWidth: 3,
                            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                            pixelOffset : new Cesium.Cartesian2(20, 0),
                            disableDepthTestDistance: Number.POSITIVE_INFINITY,
                        },
                        show: annotation.visible && itemDataset.visible
                    });
                    entity.dataset_id = annotation.dataset_id;
                    entities.push(entity);
                    entities[entities.length - 1].distancePositions = [position0, position1];
                }
            }
        }

        window.annotationList.set(annotation.id, entities);
    }
}

export function removeUnnecessaryObjects(fileIDList) {
    if (!window.loadedObjects) return;
    window.loadedObjects.forEach(function(file, key) {
        if (fileIDList && fileIDList.indexOf(key) < 0 && file.object) {
            if (file.datatype === "tiff") {
                window.MAIN_CESIUM_VIEWER.imageryLayers.remove(file.object);
            }
            else if (file.datatype === "dem") {
                if (file.object instanceof Cesium.ImageryLayer) {
                    window.MAIN_CESIUM_VIEWER.imageryLayers.remove(file.object);
                }
                else {
                    if (window.MAIN_CESIUM_VIEWER.scene.terrainProvider && window.MAIN_CESIUM_VIEWER.scene.terrainProvider._scheme !== "tms") {
                        window.MAIN_CESIUM_VIEWER.scene.setTerrain(window.worldTerrain);
                    }
                }
            }
            else if (file.datatype === "pointcloud") {
                window.MAIN_CESIUM_VIEWER.scene.primitives.remove(file.object);
            }
            else if (file.datatype === "Report") {
                if (file.object instanceof Cesium.Cesium3DTileset) {
                    window.MAIN_CESIUM_VIEWER.scene.primitives.remove(file.object);
                }
                else {
                    window.MAIN_CESIUM_VIEWER.dataSources.remove(file.object);
                }
            }
            else if (file.datatype === "img") {
                window.MAIN_CESIUM_VIEWER.entities.remove(file.object);
            }
            else if (file.datatype === "kml") {
                window.MAIN_CESIUM_VIEWER.dataSources.remove(file.object);
            }
            window.loadedObjects.delete(key);
        }
    });
}

export function locationToString(lon, lat) {
    var convertedString = "";

    convertedString += Math.abs(lat.toFixed(4)) + "°";
    if (lat > 0) convertedString += "N";
    else convertedString += "S"

    convertedString += " " + Math.abs(lon.toFixed(4)) + "°";
    if (lon > 0) convertedString += "E";
    else convertedString += "W";

    return convertedString;
}

export function getMaxAndMinAngle(pointsArray) {
    if (pointsArray.length <= 1) return null;
    let min = 1e8, max = -1e8;
    for (let i = 0; i < pointsArray.length - 1; i++) {
        let startPoint = pointsArray[i];
        let endPoint = pointsArray[i + 1];

        //Obtain vector by taking difference of the end points
        var scratch1 = new Cesium.Cartesian3();
        var difference = Cesium.Cartesian3.subtract(endPoint, startPoint, scratch1);
        difference = Cesium.Cartesian3.normalize(difference, scratch1);

        //Obtain surface normal by normalizing the starting point position
        var scratch2 = new Cesium.Cartesian3();
        var surfaceNormal = Cesium.Cartesian3.normalize(startPoint, scratch2);

        //Take the dot product of your given vector and the surface normal
        var dotProduct = Cesium.Cartesian3.dot(difference, surfaceNormal);

        //Arcos the result
        var angle = 90 - Math.acos(dotProduct) * Cesium.Math.DEGREES_PER_RADIAN;
        angle = Math.abs(angle);

        min = Math.min(min, angle);
        max = Math.max(max, angle);
    }

    return {
        min: min,
        max: max
    }
}

export function getMaxAndMinBearing(pointsArray) {
    if (pointsArray.length <= 1) return null;
    let min = 1e8, max = -1e8;
    for (let i = 0; i < pointsArray.length - 1; i++) {
        let startPoint = Cesium.Ellipsoid.WGS84.cartesianToCartographic(pointsArray[i]);
        let endPoint = Cesium.Ellipsoid.WGS84.cartesianToCartographic(pointsArray[i + 1]);

        let startLat = (startPoint.latitude);
        let startLng = (startPoint.longitude);
        let destLat = (endPoint.latitude);
        let destLng = (endPoint.longitude);

        let y = Math.sin(destLng - startLng, destLat, destLng);
        let x = Math.cos(startLat) * Math.sin(destLat) -
            Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
        let brng = Math.atan2(y, x);
        brng = Cesium.Math.toDegrees(brng);
        brng = (brng + 360) % 360;

        min = Math.min(min, brng);
        max = Math.max(max, brng);
    }

    return {
        min: min,
        max: max
    }
}

export function getMaxAndMinSlope(pointsArray) {
    if (pointsArray.length <= 1) return null;
    var min = 1e8, max = -1e8;
    for (let i = 0; i < pointsArray.length - 1; i++) {
        var startPoint = pointsArray[i];
        var endPoint = pointsArray[i + 1];

        //Obtain vector by taking difference of the end points
        var scratch1 = new Cesium.Cartesian3();
        var difference = Cesium.Cartesian3.subtract(endPoint, startPoint, scratch1);
        if (difference.x === 0 && difference.y === 0 && difference.z === 0)
            continue;
        difference = Cesium.Cartesian3.normalize(difference, scratch1);

        //Obtain surface normal by normalizing the starting point position
        var scratch2 = new Cesium.Cartesian3();
        var surfaceNormal = Cesium.Cartesian3.normalize(startPoint, scratch2);

        //Take the dot product of your given vector and the surface normal
        var dotProduct = Cesium.Cartesian3.dot(difference, surfaceNormal);

        //Arcos the result
        var angle = Math.acos(dotProduct);

        min = Math.min(min, angle);
        max = Math.max(max, angle);
    }

    return {
        min: min,
        max: max
    };
}

export function getHeightFromLocation(height) {
    if (height) {
        return (" Elevation " + Math.abs(height.toFixed(3)) + "m");
    }
    return (" Elevation 0m");
}

export function enableTiltCesium(viewer, enable) {
    viewer.scene.screenSpaceCameraController.enableTilt = enable;
}

export function cameraResetTilt(viewer, heading, pitch, roll) {
    var destination;
    var orientation = {
        heading: heading,
        pitch: pitch,
        roll: roll
    };
    var original = Cesium.Ellipsoid.WGS84.cartesianToCartographic(viewer.camera.position);
    var focus = getCameraFocus(viewer);
    if (focus === undefined) {
        destination = viewer.camera.position;
    }
    else {
        var targetFocus = Cesium.Ellipsoid.WGS84.cartesianToCartographic(focus);
        destination = Cesium.Cartesian3.fromRadians(targetFocus.longitude, targetFocus.latitude, original.height);
    }

    viewer.camera.flyTo({
        destination: destination,
        orientation: orientation,
        duration: 0.5,
        convert: false
    });
}

export function cameraReset3D(viewer, is3D) {
    if (!is3D) {
        viewer.scene.mode = Cesium.SceneMode.SCENE2D;
    }
    else {
        viewer.scene.mode = Cesium.SceneMode.SCENE3D;
    }
}

export function flyToEntity(viewer, entity) {
    viewer.flyTo(entity, {
        duration: 3.0
    });
}

export function flyToRectangle(viewer, rectangle, complete) {
    viewer.camera.flyTo({
        destination: rectangle,
        orientation: {
            heading: Cesium.Math.toRadians(0.0),
            pitch: Cesium.Math.toRadians(-90),
            roll: 0.0
        },
        complete: function () {
            if (complete) complete();
        }
    });
}

export function flyPosition(viewer, position, complete) {
    viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, (position.altitude === 0)?3000:position.altitude),
        orientation: {
            heading: Cesium.Math.toRadians(0.0),
            pitch: Cesium.Math.toRadians(-90),
            roll: 0.0
        },
        complete: function () {
            if (complete) complete();
        }
    });
}

export function flyPositionWithoutDuration(viewer, position, orientation, complete) {
    let angle = {
        heading: Cesium.Math.toRadians(0.0),
        pitch: Cesium.Math.toRadians(-90),
        roll: 0.0
    };
    if (orientation) {
        angle = orientation;
    }
    viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, (position.altitude === 0)?3000:position.altitude),
        orientation: angle,
        duration: 0,
        complete: function () {
            if (complete) complete();
        }
    });
}

export function drawOutLine(viewer, position1, position2, array) {
    var terrainSamplePositions = drawTerrainFollowingPoints(viewer, [position1, position2], 200);
    array.push(viewer.entities.add({
        polyline: {
            positions: Cesium.Ellipsoid.WGS84.cartographicArrayToCartesianArray(terrainSamplePositions),
            followSurface: false,
            width: 2,
            material: new Cesium.PolylineOutlineMaterialProperty({
                color: Cesium.Color.fromCssColorString("#FFFFFF").withAlpha(0.8),
                outlineWidth: 2,
                outlineColor: Cesium.Color.fromCssColorString("#FFFFFF").withAlpha(0.8)
            })
        }
    }));
}

export function getColorPropertyFromCssColor(cssColor) {
    switch (cssColor) {
        case 'rgb(250,60,202)':
            return 'blue';
        case 'rgb(60,120,250)':
            return 'purple';
        case 'rgb(156,39,176)':
            return 'orange';
        case 'rgb(255,152,0)':
            return 'salmon';
        case 'rgb(250,109,60)':
            return 'red';
        case 'rgb(250,60,60)':
            return 'pink';
        default:
            return 'blue';
    }
}

export function getMarkerSVG(color, stroke, opacity) {
    var newColor = "#3c78fa";
    if (color === 'blue') newColor = "rgba(60, 120, 250, " + opacity + ")";
    else if (color === 'purple') newColor = "rgba(156, 39, 176, " + opacity + ")";
    else if (color === 'orange') newColor = "rgba(255, 152, 0, " + opacity + ")";
    else if (color === 'salmon') newColor = "rgba(250, 109, 60, " + opacity + ")";
    else if (color === 'red') newColor = "rgba(250, 60, 60, " + opacity + ")";
    else if (color === 'pink') newColor = "rgba(250, 60, 202, " + opacity + ")";
    else if (color === 'highlight') newColor = "rgba(255, 255, 255, " + opacity + ")";

    let marker_pin_svg = '<svg width="40px" height="40px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">';
    if (!stroke) {
        marker_pin_svg += '<path style="fill:' + newColor + ';" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />';
    } else {
        marker_pin_svg += '<path style="fill:' + newColor + '; stroke-width:1; stroke: #fff; " d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />';
    }
    marker_pin_svg += '</svg>';

    marker_pin_svg = 'data:image/svg+xml;base64,' + window.btoa(marker_pin_svg);

    return marker_pin_svg;
}

export function getColorValue(color, opacity) {
    var newColor = Cesium.Color.fromCssColorString("#3c78fa").withAlpha(opacity);
    if (color === 'blue') {
        newColor = Cesium.Color.fromCssColorString("#3c78fa").withAlpha(opacity);
    }
    else if (color === 'purple') {
        newColor = Cesium.Color.fromCssColorString("#9c27b0").withAlpha(opacity);
    }
    else if (color === 'orange') {
        newColor = Cesium.Color.fromCssColorString("#ff9800").withAlpha(opacity);
    }
    else if (color === 'salmon') {
        newColor = Cesium.Color.fromCssColorString("#fa6d3c").withAlpha(opacity);
    }
    else if (color === 'red') {
        newColor = Cesium.Color.fromCssColorString("#fa3c3c").withAlpha(opacity);
    }
    else if (color === 'pink') {
        newColor = Cesium.Color.fromCssColorString("#fa3cca").withAlpha(opacity);
    }
    else if (color === 'highlight') {
        newColor = Cesium.Color.fromCssColorString("#ffffff").withAlpha(1);
    }

    return newColor;
}

export function addCirclePoints(viewer, position, array, color, width = 1, id) {
    if (id === undefined) {
        array.push(viewer.entities.add({
            position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
            point: {
                pixelSize: width,
                color: getColorValue(color, 1),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
        }));
    } else {
        array.push(viewer.entities.add({
            position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
            point: {
                pixelSize: width,
                color: getColorValue(color, 1),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
            id: "pointline_" + id,
        }));
    }
}

export function addPolygonVertexPoints(viewer, position, array, color, id) {
    array.push(viewer.entities.add({
        position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
        point: {
            pixelSize: 15,
            color: getColorValue(color, 1),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
        id: "polygonvertex_" + id,
    }));
}

export function getMiddleCartesianPoints(point1, point2) {
    let middle_x = (point1.x + point2.x) / 2;
    let middle_y = (point1.y + point2.y) / 2;
    let middle_z = (point1.z + point2.z) / 2;
    let middle_position = Cesium.Cartesian3.fromElements(middle_x, middle_y, middle_z);
    return middle_position;
}

export function drawHighlightedPoints(viewer, position, array, color, type, id, prefix = '') {
    if (type === "marker") {
        /* draw check image on mouse click position */
        array.push(viewer.entities.add({
            position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
            billboard: {
                image: '/check.png',
                horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                color: getColorValue(color, 1),
                width: 20,
                height: 20,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
            id: 'line_check',
        }));
    }
    else if (type === "line") {
        /* draw + image on mouse click position */
        array.push(viewer.entities.add({
            position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
            billboard: {
                image: '/plus.png',
                horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                color: getColorValue(color, 1),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                width: 15,
                height: 15,
            },
            id: (prefix !== '' ? prefix + '_' : prefix) + 'pointline_' + id,
        }));
    }
    else if (type === "polygon") {
        array.push(viewer.entities.add({
            position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
            billboard: {
                image: '/check.png',
                horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                color: getColorValue(color, 1),
                width: 20,
                height: 20,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
            id: 'polygon_check',
        }));
    }
    else if (type === "polygonmiddlepoint") {
        array.push(viewer.entities.add({
            position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
            billboard: {
                image: '/plus.png',
                horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                color: getColorValue(color, 1),
                width: 15,
                height: 15,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
            id: (prefix !== '' ? prefix + '_' : prefix) + 'polygonvertex_' + id,
        }));
    }
}

export function addMarkerPoints(viewer, position, array, color) {
    array.push(viewer.entities.add({
        position: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
        point: {
            pixelSize: 10,
            color: getColorValue(color, 1),
        },
        id: 'marker_check',
    }));
}

export function deleteHighlightedMarkersEffects(entities) {
    for (let i = 0; i < entities.length; i++) {
        let color = getColorPropertyFromEntity(entities[i], "marker");
        let image = generateMarkerImage(color[0], color[1], false);
        entities[i]._billboard._image.setValue(image);
    }
}

export function generateMarkerImage(color, opacity, highlight) {
    let image = new Image();
    image.setAttribute("colorAttribute", color);
    image.setAttribute("colorOpacity", opacity);
    image.src = getMarkerSVG(color, highlight, opacity);

    return image;
}

export function findInArray(array, elem) {
    for (var i = 0; i < array.length; i++) {
        if (array[i] === elem) return true;
    }
    return false;
}

export function saveEntities(viewer) {
    localStorage.removeItem("mapEntities");
    const project_id = localStorage.getItem("projectID");
    window.annotations[project_id].entities[window.annotations[project_id].selectedFolderName] = viewer.entities._entities._array;
}

export function getColorPropertyFromEntity(entity, type) {
    let color = getColorValue("blue", 1.0);
    if (type === "marker") {
        color = [];
        color.push (entity._billboard._image._value.attributes["0"].value);
        color.push (entity._billboard._image._value.attributes["1"].value);
    } else if (type === "line") {
        color = entity._polyline._material._color._value;
    } else if (type === "polygon") {
        color = entity._polygon._material._color._value;
    } else if (type === "volume") {

    }

    return color;
}

export function getElevationMinMax(pointsArray) { // pointsArray should be Cartographic
    let max = -1e8;
    let min = 1e8;

    for (let i = 0; i < pointsArray.length; i++) {
        max = Math.max(max, pointsArray[i].height);
        min = Math.min(min, pointsArray[i].height)
    }

    return {
        min: min,
        max: max
    };
}

export function drawLinesFromArrayValuePonts(viewer, linePoints, entityCollection, projectID, folderName, entityName, color) {
    for (let i = 0; i < linePoints.length - 1; i++) {
        let materialProperty = new Cesium.PolylineOutlineMaterialProperty({
            color: getColorValue(color, 1),
            outlineWidth: 2,
            outlineColor: Cesium.Color.WHITE
        });

        let depthFailProperty = new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.RED,
            outlineWidth: 2,
            outlineColor: Cesium.Color.BLACK,
            dashLength: 10,
        });

        let ruled_name = "project" + projectID + "_" + folderName + "_line_" + entityName + "_" + i;
        entityCollection.push(viewer.entities.add({
            polyline: {
                positions: [linePoints[i], linePoints[i + 1]],
                arcType: Cesium.ArcType.NONE,
                width: 5,
                material: materialProperty,
                depthFailMaterial: depthFailProperty,
                id: ruled_name,
            }
        }));
    }
}

export function pickLines(viewer, position) {
    let pickedObjects = viewer.scene.drillPick(position, 10);
    let retObjects = [];
    for (let i = 0; i < pickedObjects.length; i++) {
        if (pickedObjects[i].id === undefined) continue;
        if (typeof pickedObjects[i].id._id === undefined) continue;
        if (pickedObjects[i].id._id.includes("_line_")) {
            retObjects.push(pickedObjects[i]);
        }
    }

    return retObjects;
}

export function pickMarkers(viewer, position) {
    let pickedObjects = viewer.scene.drillPick(position, 10);
    let retObjects = [];
    for (let i = 0; i < pickedObjects.length; i++) {
        if (pickedObjects[i].id === undefined) continue;
        if (pickedObjects[i].id._id === undefined) continue;
        if (pickedObjects[i].id._id.includes("markerpoint")) {
            retObjects.push(pickedObjects[i]);
        }
    }

    return retObjects;
}

export function pickPolygons(viewer, position) {
    let pickedObjects = viewer.scene.drillPick(position, 10);
    let retObjects = [];
    for (let i = 0; i < pickedObjects.length; i++) {
        if (pickedObjects[i].id === undefined) continue;
        if (pickedObjects[i].id._id === undefined) continue;
        if (pickedObjects[i].id._id.includes("polygonpoint")) {
            retObjects.push(pickedObjects[i]);
        }
    }

    return retObjects;
}

export function getMousePositionEntity(viewer, position) {
    let pickedEntities = viewer.scene.drillPick(position, 10);
    for (let i = 0; i < pickedEntities.length; i++) {
        if (pickedEntities[i].id === undefined) continue;
        if (pickedEntities[i].id._id === undefined) continue;
        if (typeof pickedEntities[i].id._id !== 'string') continue;
        if (pickedEntities[i].id._id.includes('_check') ||
            pickedEntities[i].id._id.includes('line_') ||
            pickedEntities[i].id._id.includes('polygonvertex_'))
            return pickedEntities[i];
    }

    return undefined;
}

export function drawTerrainFollowingPoints(viewer, positionArray, lineSpacing) {
    let length = 100;
    let len = positionArray.length;
    var terrainSamplePositions = [];
    var cartographicStart = positionArray[len - 2];
    var cartographicEnd = positionArray[len - 1];

    length = calculateDistanceFromPoints(Cesium.Ellipsoid.WGS84.cartographicArrayToCartesianArray(positionArray));
    length = Math.round(length);

    var position = cartographicStart;
    position.height = getCesiumGlobeHeight(viewer, Cesium.Ellipsoid.WGS84.cartographicToCartesian(position));
    if (position.height !== undefined)
        terrainSamplePositions.push(position);

    for (var i = 1; i < length; i += parseInt(lineSpacing)) {
        var lon = Cesium.Math.lerp(cartographicStart.longitude, cartographicEnd.longitude, i / length);
        var lat = Cesium.Math.lerp(cartographicStart.latitude, cartographicEnd.latitude, i / length);
        position = new Cesium.Cartographic(lon, lat);

        position.height = getCesiumGlobeHeight(viewer, Cesium.Ellipsoid.WGS84.cartographicToCartesian(position));
        if (position.height !== undefined)
            terrainSamplePositions.push(position);
    }

    position = cartographicEnd;
    position.height = getCesiumGlobeHeight(viewer, Cesium.Ellipsoid.WGS84.cartographicToCartesian(position));
    if (position.height !== undefined)
        terrainSamplePositions.push(position);

    return terrainSamplePositions;
}

export function getCesiumGlobeHeight(viewer, cartesian) {
    if (viewer.scene.clampToHeightSupported) {
        let pickedResult = viewer.scene.clampToHeight(cartesian, viewer.entities._entities._array);
        if (defined(pickedResult)) {
            return Cesium.Ellipsoid.WGS84.cartesianToCartographic(pickedResult).height;
        }
    }

    return viewer.scene.globe.getHeight(Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian));
}

function defined(value) {
    return value !== undefined && value !== null;
}

export function pickPoint(viewer, position) {
    var pickedObject = viewer.scene.pick(position);
	if (pickedObject && pickedObject.id && pickedObject.id instanceof Cesium.Entity) {
                
        return pickedObject.id;
    }

	return undefined;
}

export function getWorldPosition(scene, mousePosition, snapOption, result) {
    var position;
    var cartesianScratch = new Cesium.Cartesian3();
    var rayScratch = new Cesium.Ray();
    var ray = scene.camera.getPickRay(mousePosition, rayScratch);
    if (scene.pickPositionSupported && snapOption) {
        var pickedObject = scene.pick(mousePosition, 1, 1);

        if (defined(pickedObject)) { // check to let us know if we should pick against the globe instead
            let pickPosition = scene.pickPosition(mousePosition);

            if (defined(pickPosition)) {
                return Cesium.Cartesian3.clone(pickPosition, result);
            }

            if (pickedObject.id && pickedObject.id instanceof Cesium.Entity) {
                
                let pickResult = scene.pickFromRay(ray, [pickedObject.id]);

                if (defined(pickResult)) {
                    return Cesium.Cartesian3.clone(pickResult.position, result);
                }
            }
        }
    }

    if (!defined(scene.globe)) {
        return;
    }

    position = scene.globe.pick(ray, scene, cartesianScratch);

    if (defined(position)) {
        return Cesium.Cartesian3.clone(position, result);
    }
}

export function createClampedToTerrainPoint(viewer, worldPosition) {
    let point = viewer.entities.add({
        position: worldPosition,
        point: {
            color: Cesium.Color.WHITE,
            pixelSize: 5,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
    });
    return point;
}

export function drawPolyShape(viewer, positionData, drawingMode, color) {
    var shape;
    if (drawingMode === 'line') {
        let materialProperty = new Cesium.PolylineOutlineMaterialProperty({
            color: getColorValue(color, 1),
            outlineWidth: 2,
            outlineColor: Cesium.Color.WHITE
        });

        shape = viewer.entities.add({
            polyline: {
                positions: positionData,
                clampToGround: true,
                width: 8,
                material: materialProperty
            }
        });
    }
    else if (drawingMode === 'polygon') {
        shape = viewer.entities.add({
            polygon: {
                hierarchy: positionData,
                material: new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.7))
            }
        });
    }
    return shape;
}

export function getGlobePositionWithTerrain(viewer, windowPosition) {
    var position = getWorldPosition(viewer.scene, windowPosition);

    if (!position) return position;
    var cartographicPosition = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);

    return cartographicPosition;
}

export function calculate2DAreaOfPolygon(turf, pointsArray) {
    if (pointsArray.length < 3) return 0;
    let vertexes = [];
    let elements = [];
    let first_pt = [pointsArray[0].latitude, pointsArray[0].longitude];

    for (let i = 0; i < pointsArray.length; i++) {
        let point = pointsArray[i];
        elements.push([point.latitude, point.longitude]);
    }
    elements.push(first_pt);
    vertexes.push(elements);
    let polygon_to_calculate = turf.polygon(vertexes);
    let area = turf.area(polygon_to_calculate);
    area = area * 1000 * 2.37;
    return area.toFixed(2);
}

export function calculateBearingBetweenTwoPoints(turf, pointsArray) {

    let point1 = turf.point([pointsArray[0].longitude, pointsArray[0].latitude]);
    let point2 = turf.point([pointsArray[1].longitude, pointsArray[1].latitude]);

    let bearing = turf.bearing(point1, point2);

    return bearing.toFixed(3);
}

export function getCenterPoints(pointsArray) {
    let lon = 0;
    let lat = 0;
    let count = pointsArray.length;
    let max = -1e8;

    if (count < 2) return undefined;
    for (let i = 0; i < count; i++) {
        lon += pointsArray[i].longitude;
        lat += pointsArray[i].latitude;
        max = Math.max(pointsArray[i].height, max);
    }

    lon = lon * 180 / Math.PI / count;
    lat = lat * 180 / Math.PI / count;
    return {
        lat: lat,
        lon: lon,
        height: max,
    };
}

export function calculateDistanceFromPoints(pointsArray) {
    let distance = 0;

    if (pointsArray.length <= 1) return 0;

    for (let i = 0; i < pointsArray.length - 1; i++) {
        distance += Cesium.Cartesian3.distance(pointsArray[i], pointsArray[i + 1]);
    }
    return distance;
}

export function updateGISPosition(viewer) {
    // let position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(viewer.camera.position);
    // var lon = Cesium.Math.toDegrees(position.longitude); 
    // var lat = Cesium.Math.toDegrees(position.latitude); 
    // var height = position.height;
    // document.getElementById("gis_location").innerHTML = locationToString(lon, lat, height);
}

export function getCameraFocus(viewer) {
    var rayScratch = new Cesium.Ray();
    rayScratch.origin = viewer.camera.positionWC;
    rayScratch.direction = viewer.camera.directionWC;
    return viewer.scene.globe.pick(rayScratch, viewer.scene);
}

export function animationZoom(viewer, amount) {
    var focus = getCameraFocus(viewer);
    var orientation;

    if (focus !== undefined) {
        orientation = {
            direction: viewer.camera.direction,
            up: viewer.camera.up
        };
    }
    else {
        var ray = new Cesium.Ray(viewer.camera.worldToCameraCoordinatesPoint(viewer.scene.globe.ellipsoid.cartographicToCartesian(viewer.camera.positionCartographic)), viewer.camera.directionWC);
        focus = Cesium.IntersectionTests.grazingAltitudeLocation(ray, viewer.scene.globe.ellipsoid);

        orientation = {
            heading: viewer.camera.heading,
            pitch: viewer.camera.pitch,
            roll: viewer.camera.roll
        };
    }
    var cartesian3Scratch = new Cesium.Cartesian3();
    var direction = Cesium.Cartesian3.subtract(viewer.camera.position, focus, cartesian3Scratch);
    var movementVector = Cesium.Cartesian3.multiplyByScalar(direction, amount, direction);
    var endPosition = Cesium.Cartesian3.add(focus, movementVector, focus);

    viewer.camera.flyTo({
        destination: endPosition,
        orientation: orientation,
        duration: 0.5,
        convert: false
    });
}

// added almond
export function flyToLayer(dataset) {
    
    console.log('flyToLayer():');
    console.log(dataset);
    
    if (dataset.item.datatype === "annotations") {
        if (dataset.annotationList.length > 0) {
            let annotation = dataset.annotationList[0];
            if (window.annotationList.get(annotation.id)) {
                let entities = window.annotationList.get(annotation.id);
                if (entities.length > 0) {
                    if (entities[0].position) {
                        let center = Cesium.Cartographic.fromCartesian(entities[0].position._value);
                        flyPosition(window.MAIN_CESIUM_VIEWER, {
                            longitude: Cesium.Math.toDegrees(center.longitude),
                            latitude: Cesium.Math.toDegrees(center.latitude),
                            altitude: center.height + 2000
                        });
                    }
                }
            }
        }
        
        return true;
    }
    if (dataset.item.merge) {
        if (dataset.item.datatype === "pointcloud") {
            let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id);
            if (item && item.object && item.object._root) {
                
                console.log('flyToLayer() pointcloud:');
                console.log(item.object.root.contentBoundingVolume.boundingSphere);
                
                window.MAIN_CESIUM_VIEWER.camera.flyToBoundingSphere(item.object.root.contentBoundingVolume.boundingSphere,{ duration: 2 });
                return true;
            }
            return false;
        }
        else if (dataset.item.datatype === "tiff") {
            let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id);
            if (dataset.item.location && dataset.item.location !== "") {
                let position = JSON.parse(dataset.item.location);
                let lon = position[1];
                let lat = position[0];
                flyPosition(window.MAIN_CESIUM_VIEWER, {
                    longitude: lon,
                    latitude: lat,
                    altitude: 0
                });
                return true;
            }
            else if (item && item.object) {
                flyToRectangle(window.MAIN_CESIUM_VIEWER, item.object._rectangle);
                return true;
            }
            return false;
        }
    }

    if (dataset.item.datatype === "dem") {
        if (dataset.item.location && dataset.item.location !== "") {
            let position = JSON.parse(dataset.item.location);
            let lon = position[1];
            let lat = position[0];
            flyPosition(window.MAIN_CESIUM_VIEWER, {
                longitude: lon,
                latitude: lat,
                altitude: 0
            });
            return true;
        }
    }

    for (let i = 0; i < dataset.files.length; i ++) {
        let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.files[i].id);
        if (item) {
            if (dataset.item.datatype === "pointcloud" && item.object && !dataset.item.merge && item.object._root) {
                window.MAIN_CESIUM_VIEWER.camera.flyToBoundingSphere(item.object.root.contentBoundingVolume.boundingSphere,{ duration: 2 });
                return true;
            }
            else if (dataset.item.datatype === "kml" && item.object && item.object instanceof Cesium.KmlDataSource) {
                if (item.object.entities && item.object.entities.values.length > 0) {
                    for (let entityIndex = 0; entityIndex < item.object.entities.values.length; entityIndex ++) {
                        if (item.object.entities.values[entityIndex].position) {
                            let center = Cesium.Cartographic.fromCartesian(item.object.entities.values[entityIndex].position._value);
                            
                            console.log('flyToLayer() kml:');
                            console.log(item.object.entities.values[entityIndex].position);
                            console.log(center);
                            
                            flyPosition(window.MAIN_CESIUM_VIEWER, {
                                longitude: Cesium.Math.toDegrees(center.longitude),
                                latitude: Cesium.Math.toDegrees(center.latitude),
                                altitude: center.height + 4000
                            });
                            break;
                        }
                    }
                }
                else {
                    window.MAIN_CESIUM_VIEWER.flyTo(item.object, {
                        duration: 3.0,
                    });
                }
                
                return true;
            }
            else if (dataset.item.datatype === "Report" && item.object) {
                if (item.object.entities && item.object.entities.values.length > 0) {
                    for (let entityIndex = 0; entityIndex < item.object.entities.values.length; entityIndex ++) {
                        if (item.object.entities.values[entityIndex].position) {
                            let center = Cesium.Cartographic.fromCartesian(item.object.entities.values[entityIndex].position._value);
                            flyPosition(window.MAIN_CESIUM_VIEWER, {
                                longitude: Cesium.Math.toDegrees(center.longitude),
                                latitude: Cesium.Math.toDegrees(center.latitude),
                                altitude: center.height + 2000
                            });
                            break;
                        }
                    }
                }
                else {
                    window.MAIN_CESIUM_VIEWER.flyTo(item.object, {
                        duration: 3.0,
                    });
                }
                return true;
            }
            else if (dataset.item.datatype === "img" && item.object) {
                flyPosition(window.MAIN_CESIUM_VIEWER, {
                    longitude: item.location[0],
                    latitude: item.location[1],
                    altitude: item.location[2] + 2000
                });

                return true;
            }
            else if (dataset.item.datatype === "tiff" && !dataset.item.merge) {
                let file = item.file;
                if (file.location && file.location !== "") {
                    let position = JSON.parse(file.location);
                    let lon = position[1];
                    let lat = position[0];
                    flyPosition(window.MAIN_CESIUM_VIEWER, {
                        longitude: lon,
                        latitude: lat,
                        altitude: 0
                    });
                }
                else {
                    let layer = window.MAIN_CESIUM_VIEWER.imageryLayers._layers[window.MAIN_CESIUM_VIEWER.imageryLayers.length - 1];
                    flyToRectangle(window.MAIN_CESIUM_VIEWER, layer._rectangle);
                }

                return true;
            }
        }
    }

    return false;
}

export function applyPointDensity(datasetList, point_cloud_density) {
    if (!datasetList) return;
    if (!window.loadedObjects) return;

    datasetList.forEach(function(dataset) {
        if (dataset.item.datatype === "pointcloud") {
            if (dataset.item.merge) {
                let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id);
                if (item && item.object) {
                    applyPointDensityForTileset(item.object, point_cloud_density)
                }
            }
            else {
                for (let i = 0; i < dataset.files.length; i ++) {
                    let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.files[i].id);
                    if (item && item.object) {
                        applyPointDensityForTileset(item.object, point_cloud_density)
                    }
                }
            }
        }
        else if (dataset.item.datatype === "Report") {
            for (let i = 0; i < dataset.files.length; i ++) {
                if (dataset.files[i].name.endsWith(".las")) {
                    let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.files[i].id);
                    if (item && item.object) {
                        applyPointDensityForTileset(item.object, point_cloud_density)
                    }
                }
            }
        }
    });
}

export function applyPointDensityForTileset(tileset, point_cloud_density) {
    tileset.maximumScreenSpaceError = Math.floor(16 * (100 - point_cloud_density) / 100 + 16);
}

export function applyAllTilesetStyle(datasetList) {
    if (!datasetList) return;
    if (!window.loadedObjects) return;

    datasetList.forEach(function(dataset) {
        if (dataset.item.datatype === "pointcloud") {
            if (dataset.item.merge) {
                let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id);
                if (item && item.object) {
                    applyTilesetStyle(item.object, dataset);
                }
            }
            else {
                for (let i = 0; i < dataset.files.length; i ++) {
                    let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.files[i].id);
                    if (item && item.object) {
                        applyTilesetStyle(item.object, dataset);
                    }
                }
            }
        }
        else if (dataset.item.datatype === "Report") {
            for (let i = 0; i < dataset.files.length; i ++) {
                if (dataset.files[i].name.endsWith(".las")) {
                    let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.files[i].id);
                    if (item && item.object) {
                        applyTilesetStyle(item.object, dataset);
                    }
                }
            }
        }
    });
}

// This is a helper function to re-apply the styles each time the UI/checkboxes are updated.
export function applyTilesetStyle(tileset, dataset) {
    var styleObject = {};

    styleObject.color = {
        conditions: []
    };
    styleObject.show = {
        conditions: []
    };

    styleObject.pointSize = dataset.pointSize;

    if (dataset.materialMode === "classification") {
        let count = Math.min(100, dataset.classificationList.length);
        for (let i = 0; i < count; i ++) {
            let colorCondition = ['${classification} === ' + dataset.classificationList[i].index, "color('" + dataset.classificationList[i].color + "')"];
            let showCondition = ['${classification} === ' + dataset.classificationList[i].index, dataset.classificationList[i].visible];

            styleObject.color.conditions.push(colorCondition);
            styleObject.show.conditions.push(showCondition);
        }
    }
    else if (dataset.materialMode === "elevation") {
        let rainbow_colorlist = [
            [-60, 0, 128, 220],
            [-40, 0, 128, 255],
            [-20, 0, 255, 255],
            [0, 0, 255, 0],
            [20, 255, 255, 0],
            [40, 255, 128, 0],
            [60, 255, 0, 0]
        ];

        if (tileset && tileset.boundingSphere) {
            let center = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center);
            let altitude = center.height;
            center = Cesium.Cartesian3.fromRadians(center.longitude, center.latitude);
            center = Cesium.Cartesian3.magnitude(center);

            styleObject.defines = {
                height : "length(${POSITION_ABSOLUTE}) - " + center
            };

            for (let i = 0; i < rainbow_colorlist.length; i ++) {
                rainbow_colorlist[i][0] += altitude;
                if (i === 0) {
                    let colorCondition = ['${height} < ' + rainbow_colorlist[i][0], 
                    "rgb(" + rainbow_colorlist[i][1] + ", " + rainbow_colorlist[i][2] + ", " + rainbow_colorlist[i][3] + ")"];
          
                    styleObject.color.conditions.push(colorCondition);
                }
                else {
                    let diff = rainbow_colorlist[i][0] - rainbow_colorlist[i - 1][0];
                    let diffRed = rainbow_colorlist[i][1] - rainbow_colorlist[i - 1][1];
                    let diffGreen = rainbow_colorlist[i][2] - rainbow_colorlist[i - 1][2];
                    let diffBlue = rainbow_colorlist[i][3] - rainbow_colorlist[i - 1][3];
                    let colorCondition = [
                        '${height} < ' + rainbow_colorlist[i][0],
                        "rgb(" + rainbow_colorlist[i - 1][1] + " + " + diffRed + " * (${height}" + " - "  + rainbow_colorlist[i - 1][0] + ") / " +  diff 
                        + ", " + rainbow_colorlist[i - 1][2] + " + " + diffGreen + " * (${height}" + " - "  + rainbow_colorlist[i - 1][0] + ") / " +  diff
                        + ", " + rainbow_colorlist[i - 1][3] + " + " + diffBlue + " * (${height}" + " - "  + rainbow_colorlist[i - 1][0] + ") / " +  diff + ")"
                    ];
          
                    styleObject.color.conditions.push(colorCondition);
                }
            }

            styleObject.color.conditions.push([
                '${height} > ' + rainbow_colorlist[rainbow_colorlist.length - 1][0],
                "rgb(" + rainbow_colorlist[rainbow_colorlist.length - 1][1] + ", " 
                + rainbow_colorlist[rainbow_colorlist.length - 1][2] + ", " + rainbow_colorlist[rainbow_colorlist.length - 1][3] + ")"
            ]);
        }
    }
    
    tileset.style = new Cesium.Cesium3DTileStyle(styleObject);
    tileset.maximumScreenSpaceError = 16;
    tileset.pointCloudShading.attenuation = true;
    tileset.pointCloudShading.maximumAttenuation = 32;
    tileset.pointCloudShading.eyeDomeLighting = true;
    tileset.pointCloudShading.eyeDomeLightingStrength = 1.5;
    tileset.pointCloudShading.eyeDomeLightingRadius = 1;
    tileset.pointCloudShading.baseResolution = 0;
}

export function matchLayer(viewer, tileset, dataset) {
    if (dataset.item && (dataset.item.elevation === "" || dataset.item.elevation === "0")) return;
    let elevation = (dataset.item && dataset.item.elevation && dataset.item.elevation !== "")?parseFloat(dataset.item.elevation):0;
    if (dataset.item.elevation_unit === "feet") {
       elevation = elevation / 3.2808;
    }

    const boundingSphere = tileset.boundingSphere;
    const cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center);
    const currentHeight = cartographic.height;
    const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, currentHeight);
    const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, currentHeight + elevation);
    const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
    tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);

    // Cesium.sampleTerrain(viewer.terrainProvider, 9, [cartographic])
    // .then(function(samples) {
    // });
}

export function pDistance(x, y, x1, y1, x2, y2) {
    var A = x - x1;
    var B = y - y1;
    var C = x2 - x1;
    var D = y2 - y1;
  
    var dot = A * C + B * D;
    var len_sq = C * C + D * D;
    var param = -1;
    if (len_sq !== 0) //in case of 0 length line
        param = dot / len_sq;
  
    var xx, yy;
  
    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }
  
    var dx = x - xx;
    var dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
}

export function insidePolygon(pointX, pointY, pointArray) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    
    var inside = false;
    for (var i = 0, j = pointArray.length - 1; i < pointArray.length; j = i++) {
        var xi = pointArray[i][0], yi = pointArray[i][1];
        var xj = pointArray[j][0], yj = pointArray[j][1];
        
        var intersect = ((yi > pointY) !== (yj > pointY))
            && (pointX < (xj - xi) * (pointY - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
};

export function getInspectColor(inspect_level) {
    if (inspect_level === 0) {
        return "#7f7f7f";
    }
    else if (inspect_level === 1) {
        return "#002fff";
    }
    else if (inspect_level === 2) {
        return "#00ff01";
    }
    else if (inspect_level === 3) {
        return "#ffff01";
    }
    else if (inspect_level === 4) {
        return "#fe7e01";
    }
    else if (inspect_level === 5) {
        return "#ed1b24";
    }

    return "#7f7f7f";
}

export function getSeverityColor(severity) {
    if (severity === 1) {
        return "#ed1b24";
    }
    else if (severity === 2) {
        return "#fe7e01";
    }
    else if (severity === 3) {
        return "#ffff01";
    }
    else if (severity === 4) {
        return "#00ff01";
    }
    else if (severity === 5) {
        return "#002fff";
    }

    return "#ed1b24";
}

export function parseDescription(description) {
    let properties = [];
    const parser = new DOMParser();
    const parseDocument = parser.parseFromString(description, "text/html");

    var tables = parseDocument.getElementsByTagName('table');
    for(let tableIt = 0; tableIt < tables.length; tableIt++) {
        let table = tables[tableIt];
        let columns = table.getElementsByTagName('tr');
        for(let columnIt = 0; columnIt < columns.length; columnIt++) {
            let trColumns = columns[columnIt].getElementsByTagName('td');
            if (trColumns.length > 1) {
                if (trColumns[0].getElementsByTagName('tr').length === 0) {
                    properties.push(trColumns[0].textContent);
                }
            }
        }
    }

    return properties;
}

export function getDescriptionValue(description, tag) {
    if (!description) return "";
    let properties = "";
    const parser = new DOMParser();
    const parseDocument = parser.parseFromString(description, "text/html");

    var tables = parseDocument.getElementsByTagName('table');
    for(let tableIt = 0; tableIt < tables.length; tableIt++) {
        let table = tables[tableIt];
        let columns = table.getElementsByTagName('tr');
        for(let columnIt = 0; columnIt < columns.length; columnIt++) {
            let trColumns = columns[columnIt].getElementsByTagName('td');
            if (trColumns.length > 1 && trColumns[0].textContent === tag) {
                return trColumns[1].textContent;
            }
        }
    }

    return properties;
}

export function getSubPath(dataset) {
    if (dataset.storage === "/home/ubuntu/storage/" || dataset.storage === "/var/www/html/storage/") {
        return api.serverPath + "globemap";
    }
    else if (dataset.storage === "/home/ubuntu/storage_s3/") {
        return api.s3Path + "globemap_s3";
    }

    return "globemap";
}

export function getStoragePath(dataset) {
    if (dataset.storage === "/home/ubuntu/storage/") {
        return "globemap";
    }
    else if (dataset.storage === "/home/ubuntu/storage_s3/") {
        return "globemap_s3";
    }

    return "globemap";
}

export function getImagePathLink(dataset, fileName, isFull, hasThumb, resize) {
    if (isFull) {
        return encodeURI(getSubPath(dataset) + "/datasets/"+ dataset.id + "/" + fileName);
    }
    else if (hasThumb) {
        if (resize === defaultImageSize) {
            return encodeURI(getSubPath(dataset) + "/datasets/"+ dataset.id + "/4k/" + fileName);
        }
        else {
            return encodeURI(getSubPath(dataset) + "/datasets/"+ dataset.id + "/300/" + fileName);
        }
    }

    return encodeURI(api.serverPath + "/resize/" + resize + "x" + resize + "/" + getStoragePath(dataset) + "/datasets/"+ dataset.id + "/" + fileName);
}

export function downloadFile(dataurl, filename) {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.dispatchEvent(new MouseEvent('click'));
}

export function normalizeName(name) {
    return name.replaceAll(/\s+/g, '_').replaceAll('"', '').replaceAll('\'', '').replaceAll('/', '-');
}

export function generateAnnotationTypeList() {
    let _annotationTypeList = [];

    for (let i = 0; i < 99; i ++) {
        if (i === 0) {
            _annotationTypeList.push({
                value : "joint_use",
                label: "Joint Use",
            });
        }
        else if (i === 1) {
            _annotationTypeList.push({
                value : "nesc",
                label: "NESC",
            });
        }
        else if (i === 2) {
            _annotationTypeList.push({
                value : "other",
                label: "Other",
            });
        }
        else if (i === 3) {
            _annotationTypeList.push({
                value : "right_of_way",
                label: "Right Of Way",
            });
        }
        else if (i === 4) {
            _annotationTypeList.push({
                value : "safety",
                label: "Safety",
            });
        }
        else if (i === 5) {
            _annotationTypeList.push({
                value : "test_and_treat",
                label: "Test And Treat",
            });
        }
        else if (i === 6) {
            _annotationTypeList.push({
                value : "vegetation",
                label: "Vegetation",
            });
        }
        else {
            _annotationTypeList.push({
                value : "" + i,
                label: "Annotation Type-" + (i + 1),
            });
        }
    }

    return _annotationTypeList;
}

export function generateAnnotationStatusList() {
    let _annotationStatusList = [];

    for (let i = 0; i < 5; i ++) {
        if (i === 0) {
            _annotationStatusList.push({
                value : "" + i,
                label: "Annotated",
            });
        }
        else if (i === 1) {
            _annotationStatusList.push({
                value : "" + i,
                label: "Work Order",
            });
        }
        else if (i === 2) {
            _annotationStatusList.push({
                value : "" + i,
                label: "Repaired",
            });
        }
        else {
            _annotationStatusList.push({
                value : "" + i,
                label: "Annotation Status-" + (i + 1),
            });
        }
    }

    return _annotationStatusList;
}